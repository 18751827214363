/*Games List CSS*/
.fag-games-area {
  position: relative;
  background: #080811 none repeat scroll 0 0;
  z-index: 1
}

.projectFilter {
  width: 100%;
  border: medium none;
  margin: 0 0 30px !important;
  justify-content: center;
}
.projectFilter .nav-item {
  display: inline-block;
  margin: 0 5px;
}

.projectFilter a,
.projectFilter a.active {
  display: block;
  color: #fff;
  padding: 7px 25px;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 17px;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600;
  position: relative
}

.projectFilter a:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: #0d0e1b none repeat scroll 0 0;
  z-index: -1;
  -webkit-transform: skew(40deg);
  transform: skew(40deg);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.projectFilter a.active:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #ff7a21 none repeat scroll 0 0;
  -webkit-transform: skew(-40deg);
  transform: skew(-40deg);
}

.projectFilter a:hover:after {
  background: #ff7a21 none repeat scroll 0 0;
  -webkit-transform: skew(-40deg);
  transform: skew(-40deg);
}
.projectFilter a:hover{
  color: #fff;
}

.games-single-item {
  background: #0d0e1b none repeat scroll 0 0;
  overflow: hidden;
  position: relative;
}

.games-desc {
  padding: 20px;
}

.games-thumb {
  position: relative
}

.games-thumb-image {
  position: relative;
  overflow: hidden;
}

.games-thumb-image:before {
  position: absolute;
  left: -25%;
  top: -50%;
  height: 60%;
  width: 150%;
  content: "";
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  -webkit-transform: skewY(25deg);
          transform: skewY(25deg);
  -webkit-transition: all 500ms linear;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  border-top: 2px solid #E48632
}

.game-overlay {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  text-align: center;
}

.game-overlay > .popup-youtube {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 2;
  font-size: 35px;
  color: #ff7a21;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  cursor: pointer;
}

.games-single-item:hover .games-thumb-image:before {
  height: 200%;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=95)";
}

.games-single-item:after {
  position: absolute;
  content: '';
  top: 0;
  width: 100%;
  height: 3px;
  background-color: #ff7a21;
  left: 0;
  border-radius: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all .3s;
  transition: all .3s;
}

.games-single-item:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.games-single-item:hover .game-overlay > .popup-youtube {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transform: translate(-50%, -50%) scale(1) !important;
  transform: translate(-50%, -50%) scale(1) !important;
}

.games-desc h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  margin-bottom: 5px;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600
}

.games-desc h3 a {
  color: #fff
}

.games-desc p {
  margin: 0;
  font-size: 15px;
  color: #999;
  line-height: 24px;
  text-transform: capitalize;
}

.game-rating {
  width: auto;
  height: auto;
  padding: 0;
  text-align: center;
  margin: 5px 0;
}

.game-rating h4 {
  font-size: 24px;
  font-size: 1.5rem;
}

.game-rating ul li {
  display: inline;
  margin: 0 5px 0 0;
  color: #E48632
}
.games-item{
  margin-top: 30px;
  display: block;
}


.game-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 10px
}

.game-price h4 {
  font-size: 20px;
  color: #fff
}

.game-price h4 del {
  color: rgba(255, 255, 255, .5)
}

.game-price .off {
  margin-left: 3px;
  color: #f34f4f;
  font-style: italic;
  font-size: 14px;
}

.game-price p.free {
  color: #2bd964
}

.game-action a.fag-btn-outline {
  padding: 6px 20px;
  border: 1px solid #FFF;
  font-size: 16px;
  color: #FFF;
  text-transform: uppercase;
  line-height: 25px;
  font-family: 'Rajdhani', sans-serif;
  display: inline-block;
  font-weight: 600;
}

.game-action a.fag-btn-outline:hover {
  border: 1px solid #ff7a21
}

/*Responsive*/

@media(max-width:575px){
  .projectFilter{
    display: block !important;
  }
  .projectFilter .nav-item {
    display: block;
    margin: 5px;
    text-align: center;
  } 
  .projectFilter a:after {
    transform: skew(0deg);
  }
  .projectFilter a.active:after {
    transform: skew(0deg);
  }
}